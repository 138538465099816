import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useNavigate } from "react-router";
import { Button, Col, Row } from "reactstrap";
import "../../css/style.css";
import PlaceholderImg from "../../images/Profile_Picture.png";

const CityMap = ({ profiles, city }) => {
  const navigate = useNavigate();
  const defaultPosition = [29.383845, -94.9027];
  const customIcon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  // Ensure city is defined before filtering
  const filteredProfiles = city
    ? profiles.filter((profile) => profile.city && profile.city.toLowerCase() === city.toLowerCase())
    : profiles;
  return (
    <MapContainer
      className=""
      center={defaultPosition}
      zoom={3}
      maxZoom={15}
      minZoom={2}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic291cmFiaHBhdGlsIiwiYSI6ImNseHZpenBieDI2YmYybHFyZGp2M2I2NzkifQ.4GSWoc4tqp65I7gJlxLzvQ`}
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MarkerClusterGroup chunkedLoading>
        {profiles?.map(
          (profile) =>
            profile.city && (
              <Marker
                key={profile.id}
                position={[
                  profile?.latitude ? profile.latitude : 29.383845,
                  profile?.longitude ? profile.longitude : -94.9027,
                ]}
                icon={customIcon}
              >
                <Popup>
                  <div className="w-100 row m-0">
                    <div className="col-5 px-0">
                      <div className="justify-center" style={{ height: "160px" }}>
                        <img
                          src={profile?.profile_image_path || PlaceholderImg}
                          alt={profile?.company_name}
                          className="img-fluid rounded-start-5"
                          style={{ width: "100%", height: "inherit", objectFit: "contain" }}
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <h5 className="lead-text fs-4 my-2 mt-2 w-100">{profile?.company_name}</h5>
                      <p className="lead-text fs-5 my-2 mt-2 w-100 fw-light">{profile?.city}</p>
                      <div className="mt-0 w-100">
                        <p className="fs-6 mt-0">Starting from: $ {profile?.max_packages?.[0]?.package_price || 0}</p>
                      </div>
                      <div className="my-2 justify-center">
                        <Button
                          color="primary"
                          className=""
                          onClick={() => navigate(`/dj-profiles/specificDj/${profile.id}`)}
                        >
                          View Details
                        </Button>
                      </div>
                    </div>
                  </div>
                </Popup>
              </Marker>
            )
        )}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default CityMap;
