import React from "react";
import { useNavigate } from "react-router";
import { Icon } from "../../../components/Component";
import { Button } from "reactstrap";

const ServiceCards = () => {
  const navigate = useNavigate();

  const ServiceCardData = {
    data: [
      {
        title: "DJ",
        description:
          "Bring your event to life with our professional DJs who know how to read the crowdand keep the energy high all night long. From weddings to corporate events, we createthe perfect soundtrack for any occasion.",
      },
      {
        title: "Uplighting",
        description:
          "Transform your venue with our elegant uplighting. We tailor the lighting to match your theme, creating an ambiance that leaves a lasting impression on your guests.",
      },
      {
        title: "Photo Booth",
        description:
          " Capture the fun and create lasting memories with our interactive photo booth. With customizable backgrounds and fun props, your guests will love sharing their moments.",
      },
      {
        title: "360 Photo Booth",
        description:
          " Elevate your event with our 360 Photo Booth. Guests can strike a pose and get a dynamic, immersive video that they’ll want to share instantly.",
      },
      {
        title: "Master of Ceremonies",
        description:
          "Ensure your event runs smoothly with our experienced MCs who engage your guests and keep the festivities on track, making sure moment is memorable.",
      },
      {
        title: "Monogram",
        description:
          " Personalize your event with custom monogram lighting. Whether it’s your initials, logo, or a special design, we’ll project it in a way that adds a unique touch to your celebration.",
      },
      {
        title: "Audio/Visual",
        description:
          "Impress your audience with top-notch audio and visual equipment. From crystal-clear sound systems to stunning visual displays, we handle all your A/V needs with professionalism.",
      },
      {
        title: "Equipment Rental",
        description:
          " Need extra gear? We offer a wide range of high-quality equipment rentals, from sound systems to lighting, ensuring your event has everything it needs to be spectacular.",
      },
      {
        title: "Cold Sparks",
        description:
          " Create unforgettable moments with our Col-3d Sparks. These safe, indoor fireworks will light up your event, making it truly spectacular without the heat or smoke of traditional pyrotechnics.",
      },
      {
        title: "Event Venues",
        description:
          " Whether you’re planning a wedding, corporate event, or private party, we partner with top venues to provide the perfect backdrop for your special occasion.",
      },
      {
        title: "Wedding",
        description:
          "Make your wedding day truly magical with our tailored DJ services. From the ceremony to the reception, we’ll curate the perfect playlist that reflects your love story.",
      },
      {
        title: "Corporate Event",
        description:
          " Elevate your corporate event with our professional DJ services. We provide the right mix of music and atmosphere to keep your guests engaged and entertained.",
      },
      {
        title: "Private Party",
        description:
          "Hosting a private event? Let us create an unforgettable experience with custom music selections and an atmosphere that’s tailored to your taste.",
      },
      {
        title: "Club",
        description:
          "Turn up the energy at your club with our DJs who specialize in high-energy beats and seamless transitions, keeping the dance floor packed all night.",
      },
      {
        title: "Mobile DJ",
        description:
          "Wherever your event takes you, our Mobile DJs bring the party to you with all the equipment needed to ensure a flawless performance.",
      },
      {
        title: "Emcee Services",
        description:
          "Keep your event flowing smoothly with our charismatic emcees who entertain, engage, and ensure everything runs according to plan.",
      },
      {
        title: "DJ and Live Music Combo",
        description:
          "Get the best of both worlds with our DJ and live music combo. Perfect for those who want the energy of a DJ and the charm of live musicians.",
      },
      {
        title: "School Dance",
        description:
          "Make your school dance the talk of the year with our DJ services that cater to the tastes of today’s students, ensuring a night full of fun and memories.",
      },
      {
        title: "Themed Event",
        description:
          "Whether it’s a 1920s Gatsby gala or a tropical beach party, we tailor our music and setup to fit your event’s theme perfectly.",
      },
      {
        title: "Karaoke",
        description:
          "Let your guests be the star of the show with our karaoke services. With an extensive song list and top-quality equipment, it’s the perfect addition to any party.",
      },
      {
        title: "Lighting and Visual Effects",
        description:
          "Set the stage with our cutting-edge lighting and visual effects. From lasers to color washes, we create a visual experience that complements your event.",
      },
      {
        title: "Sound System Rental",
        description:
          "Need powerful sound? Our top-of-the-line sound system rentals ensure your event sounds amazing, no matter the size or location.",
      },
      {
        title: "Custom Mixes and Remixes",
        description:
          "Want something unique? We create custom mixes and remixes tailored to your event, giving you a one-of-a-kind soundtrack.",
      },
      {
        title: "Interactive Games and Activities",
        description:
          "Engage your guests with interactive games and activities, all led by our lively DJs and MCs, ensuring everyone has a great time.",
      },
      {
        title: "Event Planning and Coordination",
        description:
          "From the first consultation to the final song, we take care of all the details, making your event planning stress-free and successful.",
      },
      {
        title: "Live Streaming Services",
        description:
          "Share your event with the world! Our live streaming services allow you to broadcast your event in real-time, so no one misses out on the action.",
      },
      {
        title: "Ceremony Music and Microphone Services",
        description:
          "Ensure every word and note is heard clearly with our ceremony music and microphone services, designed to enhance your special moments.",
      },
      {
        title: "Photo Booth Services",
        description:
          "Our full-service photo booths come with a variety of props and customizable options, adding fun and flair to your event.",
      },
      {
        title: "Custom Playlist Creation",
        description:
          "We work with you to create a playlist that reflects your personal taste and the mood you want to set, ensuring the music is exactly what you envisioned.",
      },
      {
        title: "Dancing on the Cloud Services",
        description:
          "Make your first dance unforgettable with our Dancing on the Cloud effect, creating a magical, cloud-like atmosphere on the dance floor.",
      },
      {
        title: "Ceremony Cocktail Hour and Reception",
        description:
          "We tailor our DJ services to each phase of your wedding, providing the perfect soundtrack from the ceremony to the last dance of the night.",
      },
      {
        title: "Instant Spotlight",
        description:
          "Highlight the most important moments with our Instant Spotlight service. Perfect for first dances, speeches, or any moment you want to stand out, it creates a breathtaking effect that your guests will remember.",
      },
      {
        title: "Glow Tube Lighting",
        description:
          "Light up the night with our Glow Tube Lighting. These vibrant, color-changing LED tubes pulse to the beat, adding a dynamic visual element that takes your event’s atmosphere to the next level.",
      },
    ],
  };
  return (
    <>
      <div class="container my-5">
        <div class="text-center mb-3">
          <div class="fs-4">Explore Our Services</div>
          <p className="mb-0">
            Scroll down to discover the full range of services we offer, each designed to elevate your event and create
            unforgettable memories. From our core DJ services to unique offerings like Cold Sparks and Glow Tube
            Lighting, we provide everything you need to make your event truly spectacular.
          </p>
          <div class="fs-2 fw-medium text-primary mt-2">Transform Your Event with Our Versatile DJ Services</div>
        </div>
        <div
          class="row g-4 py-2 overflow-auto mt-1"
          style={{ height: "600px", scrollbarWidth: "none" }}
        >
          {ServiceCardData?.data.map((item, idx) => (
            <div class="col-auto col-sm-6 col-lg-4 my-3 justify-center" key={idx}>
              <div class="cardUI px-4 py-3 pb-2">
                <div class="card-detailsUI">
                  <div class="d-flex align-center mb-2 text-titleUI">
                    <div class="fs-1 align-center">
                      <Icon name="play-circle"></Icon>
                    </div>
                    <div class="fs-16px mx-1 fw-semibold">{item?.title}</div>
                  </div>
                 
                  <div>{item.description}</div>
                </div>
                <button class="card-buttonUI" onClick={() => navigate("/dj-profiles/1")}>
                  More info
                </button>
              </div>
            </div>
          ))}         
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
