import React, { useContext, useEffect } from "react";
import Head from "../../layout/head/Head";
import { Card } from "reactstrap";
import { Block, Row, Col, PreviewAltCard } from "../../components/Component";
import DjEnquriy from "./DjEnquriy";
import SubMenu from "./SubMenu";
import Professionalsummery from "./Professionalsummery";
import Portfolio from "./Portfolio";
import Review from "./Review";
import ImageCarousel from "./ImageCarousel";
import { useQuery } from "react-query";
import { getAllCompanies } from "../../http/get/getApi";
import { useNavigate, useParams } from "react-router";
import { ShimmerThumbnail } from "react-shimmer-effects";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import NoFound from "../../images/Login-No-Background.png";
import { createcontext } from "../../layout/Index";

const SelectDJ = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { heroSection, setHeroSection } = useContext(createcontext);

  useEffect(() => {
    setHeroSection(true);
  }, []);

  //get specific companies data from its id
  const {
    data: companyData,
    isLoading: companyDataLoading,
    isError: companyDataError,
  } = useQuery({
    queryKey: ["get-company-by-id", id],
    queryFn: () => getAllCompanies({ id: id }),
    keepPreviousData: true,
  });

  const cookieValue = Cookies.get("authToken");

  const showSignMessege = () => {
    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text: "Please sign in to book your DJ",
        imageUrl: NoFound,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${id}`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    } else {
      return navigate(`/dj-profiles/specificDj/${id}/dj-bookevent`);
    }
  };

  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      {companyDataLoading ? (
        <Row>
          <Col lg={8}>
            <div className="p-5">
              <ShimmerThumbnail height={700} width={1000} />
            </div>
          </Col>
          <Col lg={4}>
            <div className="p-5">
              <ShimmerThumbnail height={600} width={400} />
            </div>
          </Col>
        </Row>
      ) : (
        <Block>
          <div id="home">
            <Row className="g-0">
              <Col lg="8">
                <PreviewAltCard className="card-bordered h-100">
                  <ImageCarousel companyData={companyData?.data?.[0]} />
                </PreviewAltCard>
              </Col>
              <Col lg="4">
                <PreviewAltCard className="card-bordered h-100">
                  <DjEnquriy companyData={companyData?.data?.[0]} />
                </PreviewAltCard>
              </Col>
              <SubMenu />
            </Row>
          </div>
          <Row className="g-0">
            <Col xxl="12" className="m-0">
              <div id="Professionalsummery">
                <Card className="card-bordered card-full md-wid overflow-auto">
                  <Professionalsummery companyData={companyData?.data?.[0]} />
                </Card>
              </div>
            </Col>
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full md-wid overflow-auto">
                <Portfolio companyData={companyData?.data?.[0]} isLoading={companyDataLoading} />
              </Card>
            </Col>
            <Col xxl="12" className="m-0">
              <Card className="card-bordered card-full overflow-auto">
                <Review companyData={companyData?.data?.[0]} />
              </Card>
            </Col>
          </Row>
        </Block>
      )}
    </React.Fragment>
  );
};

export default SelectDJ;
