import React, { createContext, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "../../images/header_logo.png";
import { useTheme } from "../provider/Theme";
import Cookies from "js-cookie";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import { createcontext } from "../Index";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
    "bg-white": true,
    "text-dark": true,
  });

  function cookieVariable() {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }
  // const setLogout = () => {
  //   const now = new Date();
  //   const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
  //   Cookies.set("logoutURL", process.env.REACT_APP_PUBLIC_URL, {
  //     domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //     expires: expires,
  //   });
  //   const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
  //   window.location.href = logoutUrl;
  // };
  const authTokenChk = Cookies.get("authToken");

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  // const getCoordinates = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(cityName)}&format=json`
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();

  //     if (data.length > 0) {
  //       const location = data[0];
  //       setFieldValue("latitude", location.lat);
  //       setFieldValue("longitude", location.lon);
  //       alert(null);
  //     } else {
  //       alert("Unable to geocode the address. Please try again.");
  //     }
  //   } catch (error) {
  //     alert("Error occurred while fetching location data.");
  //   }
  // };
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLong(position.coords.longitude);
        },
        () => {
          alert("Geolocation is not supported by this browser.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const { heroSection, setHeroSection } = useContext(createcontext);
  useEffect(() => {
    setHeroSection(true);
  }, []);

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap d-flex">
          <div className="nk-header-app-name d-flex align-items-center" style={{ width: "150px" }}>
            {heroSection === true ? (
              <div className="nk-header-app-logo h-100 w-60">
                {console.log("heroSection === true")}
                <Link to="/" className="nav-link text-dark w-100">
                  <img src={Logo} alt="DJ Logo" className="my-1" />
                </Link>
              </div>
            ) : (
              currentUrl !== "/" &&
              currentUrl !== "/home" && (
                <div className="nk-header-app-logo h-100 w-60">
                  {console.log("heroSection === false and not on / or /home")}
                  <Link to="/" className="nav-link text-dark w-100">
                    <img src={Logo} alt="DJ Logo" className="my-1" />
                  </Link>
                </div>
              )
            )}
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav d-flex list-unstyled mb-0">
              <li className="nav-item me-3 d-none d-md-block">
                <Link
                  to={`${process.env.REACT_APP_PUBLIC_URL}`}
                  className={currentUrl === "/" || currentUrl === "/home" ? "text-primary" : "infoSection"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to="about" className={currentUrl === "/about" ? "text-primary nav-link" : "infoSection"}>
                  About
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link
                  to="dj-section"
                  className={currentUrl === "/dj-section" ? "text-primary nav-link" : "infoSection"}
                >
                  DJ Only
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to={`https://blogs.ipartydjcrm.com/`} className="infoSection" target="_blank">
                  Blog
                </Link>
              </li>
              <li className="nav-item me-3 d-none d-md-block">
                <Link to="contact" className={currentUrl === "/contact" ? "text-primary nav-link" : "infoSection"}>
                  Contact
                </Link>
              </li>
              {authTokenChk ? (
                <li className="nav-item">
                  <User />
                </li>
              ) : (
                <li className="nav-item d-none d-md-block">
                  <Link to={`${process.env.REACT_APP_ACCOUNT_URL}login`} className="nav-link" onClick={cookieVariable}>
                    <button className="btn btn-primary">Sign In</button>
                  </Link>
                </li>
              )}
              <li className="nav-item me-3 d-md-none">
                <AppDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
