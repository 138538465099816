import React, { useContext } from "react";
import { Link } from "react-router-dom";
import footerImgDark from "../../images/IPartyDJ_MIX.png";
import { Col, Row } from "reactstrap";
import { BlockTitle, Icon } from "../../components/Component";
import Cookies from "js-cookie";
import { AiFillTikTok } from "react-icons/ai";
import { createcontext } from "../Index";
import Tiktok from "../../images/tiktok.png";
const Footer = () => {
  const handleCookieSet = (val) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 5 * 1000);
    Cookies.set("serviceId", val, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  const { heroSection, setHeroSection } = useContext(createcontext);
  return (
    <div className="nk-footer p-0">
      {heroSection === false ? (
        ""
      ) : (
        <>
          <div className="container-fluid">
            <Row className="justify-around m-3">
              <Col lg={3} className="justify-center">
                <div className="d-flex flex-column justify-center">
                  <div className="justify-center p-3" style={{ width: "150px" }}>
                    {/* <img src={footerImg} alt="DJ Section" className="img-fluid" /> */}
                    <Link to="/">
                      <img src={footerImgDark} alt="DJ Section" className="" />
                    </Link>
                  </div>
                  <div className="d-flex justify-center flex-column mt-2">
                    <BlockTitle className="mx-1 mb-0"> </BlockTitle>
                    <span style={{ fontSize: "18px" }} className="mx-1 fw-bold">
                      Follow Us On
                    </span>
                    <div className="d-flex my-1">
                      <span className="mx-1">
                        <Link to="https://www.facebook.com/profile.php?id=100057235275531" target="_blank">
                          <div class="parentUI">
                            <div class="childUI child-1UI">
                              <button class="buttonUI btn-2UI align-center">
                                <Icon name="facebook-fill" style={{ color: "#316FF6" }}></Icon>
                              </button>
                            </div>
                          </div>
                        </Link>
                      </span>
                      <span className="mx-1">
                        <Link to="https://www.instagram.com/ipartydjmix/?hl=en" target="_blank">
                          <div class="parentUI">
                            <div class="childUI child-2UI">
                              <button class="buttonUI btn-2UI align-center">
                                <Icon name="instagram" style={{ color: "#E1306C" }}></Icon>
                              </button>
                            </div>
                          </div>
                        </Link>
                      </span>
                      <span className="mx-1">
                        <Link to="#" target="_blank">
                          <div class="parentUI">
                            <div class="childUI child-1UI">
                              <button class="buttonUI btn-2UI align-center">
                                <Icon name="linkedin-round" style={{ color: "#0072b1" }}></Icon>
                              </button>
                            </div>
                          </div>
                        </Link>
                      </span>
                      <span className="mx-1">
                        <Link to="#" target="_blank">
                          <div class="parentUI">
                            <div class="childUI child-1UI">
                              <button class="buttonUI btn-2UI align-center">
                                <img src={Tiktok} alt="" />
                              </button>
                            </div>
                          </div>
                        </Link>
                      </span>
                      <span className="mx-1">
                        <Link to="https://in.pinterest.com/ipartydjmix/" target="_blank">
                          <div class="parentUI">
                            <div class="childUI child-1UI">
                              <button class="buttonUI btn-2UI align-center">
                                <Icon name="pinterest-round" style={{ color: "#E60023" }}></Icon>
                              </button>
                            </div>
                          </div>
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={9}>
                <div className="row justify-between">
                  <div className="col-md-4 col-auto p-0">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        Company
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}`}
                          className={currentUrl === "/" || currentUrl === "/home" ? "text-primary" : "infoSection"}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="">
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}about`}
                          className={currentUrl === "/about" ? "text-primary" : "infoSection"}
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link to={`https://blogs.ipartydjcrm.com/`} className="infoSection">
                          Blog
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}contact`}
                          className={currentUrl === "/contact" ? "text-primary" : "infoSection"}
                        >
                          Contact
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}client-section`}
                          className={currentUrl === "/client-section" ? "text-primary" : "infoSection"}
                        >
                          Client
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-auto p-0">
                    <BlockTitle className="text-primary" style={{ fontSize: "18px" }}>
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        High Profile DJ's
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/6`}
                          className="infoSection"
                        >
                          DJ Mike Production
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/7`}
                          className="infoSection"
                        >
                          DJ Axcess
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/9`}
                          className="infoSection"
                        >
                          Alliance Entertainment Group
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/10`}
                          className="infoSection"
                        >
                          Vibe Setters Entertainment
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/11`}
                          className="infoSection"
                        >
                          A-Roc Entertainment
                        </Link>
                      </li>{" "}
                    </ul>
                  </div>
                  <div className="col-md-4 col-auto p-0">
                    <BlockTitle className="text-primary" style={{ fontSize: "18px" }}>
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        Account
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      <li>
                        <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=client`} className="infoSection">
                          Sign-In as Client
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`} className="infoSection">
                          Sign-In as DJ-Company
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="nk-footer-wrap justify-center p-1 border " style={{ fontSize: "14px" }}>
            <div className="nk-footer-copyright">
              {" "}
              &copy; 2024{" "}
              {process.env.REACT_APP_PUBLIC_URL === "http://localhost:3000/" || "https://ipartydjcrm.com/"
                ? "ipartydjcrm"
                : process.env.REACT_APP_PUBLIC_URL === "http://localhost:3000/" || "https://ipartydjmix.com/"
                ? "ipartydjmix"
                : ""}
              . All rights reserved
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Footer;
