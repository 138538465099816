import React, { useEffect, useState } from "react";
import { Block, PreviewCard } from "../../components/Component";
import { Button, Card, CardBody, CardText, CardTitle, Col, Row, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import NoFound from "../../images/Login-No-Background.png";
import { color } from "framer-motion";

const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};

const DjEnquriy = ({ companyData }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    event_type: "",
    event_name: "",
    event_package: "",
    event_message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      event_type: "",
      event_name: "",
      event_package: "",
      event_message: "",
    });
    reset({});
  };

  const onFormSubmit = async (form) => {
    const { name, email, phone_number, event_type, event_name, event_package, event_message } = form;
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("event_type", event_type);
      formData.append("event_name", event_name);
      formData.append("event_package", event_package);
      formData.append("enquiry_message", event_message);
      formData.append("company_id", id);
      const enquirydata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/enquiry/add-enquiry`, {
        method: "POST",
        body: formData,
      });
      if (enquirydata && enquirydata.status) {
        const submittedData = enquirydata?.data;
        toast.success("Inquiry Sent Successfully");
        resetForm();
      } else {
        toast.danger(enquirydata.message);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const blockNumbers = (val) => {};

  const cookieValue = Cookies.get("authToken");
  const showSignMessege = () => {
    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text: "Please sign in to book your DJ",
        imageUrl: NoFound,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}dj-profiles/specificDj/${id}`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    } else {
      // return navigate(`/dj-profiles/specificDj/${id}/dj-bookevent`);
      return navigate(`/dj-profiles/specificDj/${id}/dj-bookevent`, { state: { companyData } });
    }
  };

  return (
    <div>
      <Block size="lg">
        <Card className="align-center">
          <CardBody className="card-inner p-0">
            <CardText>"Ready to Party? Book Your DJ Today!"</CardText>
            <div className="d-flex justify-around align-center ">
              <div className="w-100 my-1">
                <Button color="primary" className="rounded justify-center w-100" onClick={showSignMessege}>
                  Book Now
                </Button>
              </div>
            </div>
            <Card className="my-2 align-center">
              <div className="card-bordered w-100 p-3 border-1 border-primary">
                <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Full Name <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            placeholder="Enter Full Name"
                            {...register("name", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            maxLength={30}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Email <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "This is required",
                              pattern: {
                                value:
                                  // eslint-disable-next-line
                                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "Enter a valid email address",
                              },
                              maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                            })}
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Contact Number <Required />
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            id="phone_number"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            placeholder="Enter Contact Number"
                            {...register("phone_number", {
                              required: "This is required",
                              maxLength: {
                                value: 15,
                                message: "You rich maximum contact number limit!",
                              },
                            })}
                            value={formData.phone_number}
                            onChange={(e) => {
                              if (e.target.value.length <= 15) {
                                setFormData({ ...formData, phone_number: e.target.value });
                              } else {
                                blockNumbers();
                              }
                            }}
                          />
                          {errors.phone_number && <span className="invalid">{errors.phone_number.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">
                            Event Name <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="event_name"
                            placeholder="Enter Event Name"
                            {...register("event_name", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            value={formData.event_name}
                            onChange={(e) => setFormData({ ...formData, event_name: e.target.value })}
                          />
                          {errors.event_name && <span className="invalid">{errors.event_name.message}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">Event Type</label>
                          <input
                            className="form-control"
                            type="text"
                            id="event_type"
                            placeholder="Enter Event Type"
                            {...register("event_type", {
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            value={formData.event_type}
                            onChange={(e) => setFormData({ ...formData, event_type: e.target.value })}
                          />
                          {errors.event_type && <span className="invalid">{errors.event_type.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message">Services/Packages</label>
                          <input
                            className="form-control"
                            type="number"
                            id="event_package"
                            placeholder="$ Enter Package"
                            {...register("event_package", {
                              minLength: {
                                value: 2,
                                message: "Please enter at least 2 characters",
                              },
                            })}
                            value={formData.event_package}
                            onChange={(e) => setFormData({ ...formData, event_package: e.target.value })}
                          />
                          {errors.event_package && <span className="invalid">{errors.event_package.message}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <div className="form-control-wrap my-2">
                      <label htmlFor="event_message">Event Message</label>
                      <textarea
                        className="form-control h-100px"
                        type="text"
                        id="event_message"
                        placeholder="Enter Event Message"
                        {...register("event_message", {
                          pattern: {
                            message: "Only alphabets and spaces are allowed",
                          },
                          minLength: {
                            value: 3,
                            message: "Please enter at least 3 characters",
                          },
                        })}
                        maxLength={500}
                        value={formData.event_message}
                        onChange={(e) => setFormData({ ...formData, event_message: e.target.value })}
                      />
                      {/* <input
                      className="form-control h-100px"
                      type="text"
                      id="event_message"
                      placeholder="Enter Event Message"
                      {...register("event_message", {
                        pattern: {
                          message: "Only alphabets and spaces are allowed",
                        },
                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      value={formData.event_message}
                      onChange={(e) => setFormData({ ...formData, event_message: e.target.value })}
                    /> */}
                      {errors.event_message && <span className="invalid">{errors.event_message.message}</span>}
                    </div>
                  </div>
                  <div className="justify-center">
                    {loading ? (
                      <Button disabled color="primary">
                        <Spinner size="sm" />
                        <span> Loading... </span>
                      </Button>
                    ) : (
                      <Button color="primary" className="justify-center w-100 rounded" type="submit">
                        Send Inquiry
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </Card>
          </CardBody>
        </Card>
      </Block>
    </div>
  );
};

export default DjEnquriy;
