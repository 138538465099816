import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle } from "../../components/block/Block";
import { PreviewCard } from "../../components/preview/Preview";
import { Card, Label, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Icon from "../../components/icon/Icon";
import classnames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router";
import Content from "../../layout/content/Content";
import { Button, Col, Row } from "../../components/Component";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { formatDates, getCookie, getDateStructured, setDateForPicker } from "../../utils/Utils";
import { getAllCategories, getAttire, getVenue } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { format } from "path-browserify";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import djImage from "../../images/DJ_Section2.png";
import Required from "../components/Required";

const DJBookingEvent = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    eventname: "",
    eventType: "",
    attire: "",
    guestcount: "",
    eventdate: "",
    setuptime: "",
    starttime: "",
    endtime: "",
    eventvenue: "",
    packageid: "1",
    packageamount: "2000",
    retainer: "",
    retainerpaymentamount: "",
    schpayment2: "",
    sch2paymentamount: "",
    schpayment3: "",
    sch3paymentamount: "",
  });

  const resetForm = () => {
    setFormData({
      eventname: "",
      eventType: "",
      attire: "",
      guestcount: "",
      eventdate: "",
      setuptime: "",
      starttime: "",
      endtime: "",
      eventvenue: "",
    });
  };
  const { companyData } = location.state || {};
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const [loading, setLoading] = useState(false);
  const [eventDateSelected, setEventDateSelected] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState();

  const {
    data: categoryList,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useQuery({
    queryKey: ["get-category-list"],
    queryFn: () => getAllCategories(),
  });

  const {
    data: attireList,
    isLoading: attireListLoading,
    isError: attireListError,
  } = useQuery({
    queryKey: ["get-attire-list"],
    queryFn: () => getAttire(),
  });

  const {
    data: venueList,
    isLoading: venueListLoading,
    isError: venueListError,
  } = useQuery({
    queryKey: ["get-venue-list"],
    queryFn: () => getVenue(),
  });

  const {
    data: packageList,
    isLoading: packageListLoading,
    isError: packageListtError,
  } = useQuery({
    queryKey: ["get-package-list"],
    queryFn: () => getVenue(),
  });

  const EventTypes = {
    data: [
      {
        steps: "Wedding",
        values: "1",
      },
      {
        steps: "Birthday",
        values: "2",
      },
      {
        steps: "Engagement",
        values: "3",
      },
      {
        steps: "Anniversary",
        values: "4",
      },
    ],
  };
  const AttireData = {
    data: [
      {
        steps: "Tuxido",
        values: "1",
      },
      {
        steps: "Suit",
        values: "2",
      },
      {
        steps: "Casual",
        values: "3",
      },
    ],
  };
  const VenueData = {
    data: [
      {
        steps: "UNICO 20 87 Riviera Maya, Mexico",
        values: "1",
      },
      {
        steps: "One Great George Street, United Kingdom",
        values: "2",
      },
      {
        steps: "Palazzo Versace Dubai, UAE",
        values: "3",
      },
      {
        steps: "Atlantis The Palm, Dubai",
        values: "4",
      },
      {
        steps: "Mahiki, United KingdomUnited Kingdom",
        values: "5",
      },
    ],
  };
  const PackageData = {
    data: [
      {
        steps: "Wedding",
        values: "1",
        price: "2000",
      },
      {
        steps: "Birthday",
        values: "2",
        price: "1000",
      },
      {
        steps: "Engagement",
        values: "3",
        price: "1200",
      },
      {
        steps: "Mega Event",
        values: "4",
        price: "5000",
      },
      {
        steps: "Other Parties",
        values: "5",
        price: "3000",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const next = parseInt(activeIconTab) + 1;
  const back = parseInt(activeIconTab) - 1;

  useEffect(() => {
    // if (!isLoading && formSubmitted) {
    if (formSubmitted) {
      if (activeIconTab !== 3) {
        setActiveIconTab(next.toString());
      }
      setFormSubmitted(false);
    }
  }, [formSubmitted, next]);

  useEffect(() => {
    const defaultPackage = PackageData.data.find((item) => item.values === formData.packageid);
    if (defaultPackage) {
      setSelectedPackage(defaultPackage.price);
      setFormData({ ...formData, retainerpaymentamount: defaultPackage.price });
    }
  }, [formData.packageid]);
  useEffect(() => {
    if (categoryList && categoryList?.data?.Category.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        eventType: categoryList?.data?.Category?.[0].id,
      }));
    }
  }, [categoryList]);
  useEffect(() => {
    if (attireList && attireList?.data?.packages.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        attire: attireList?.data?.packages?.[0]?.id, // Use the first title from the attire response
      }));
    }
  }, [attireList]);
  useEffect(() => {
    if (venueList && venueList?.data?.list.length) {
      setFormData((prevState) => ({
        ...prevState,
        eventvenue: venueList?.data?.list?.[0]?.id, // Use the first venue_name from the venue response
      }));
    }
  }, [venueList]);

  const handleSaveAndNext = () => {
    setFormSubmitted(true);
  };

  const eventSumbmit = async () => {
    setLoading(true);
    try {
      const formdata = new FormData();
      formdata.append("company_id", id);
      formdata.append("event_name", formData.eventname);
      formdata.append("event_type", formData.eventType);
      formdata.append("setup_time", formatDates(formData.setuptime));
      formdata.append("start_date_time", formatDates(formData.starttime));
      formdata.append("end_date_time", formatDates(formData.endtime));
      formdata.append("attire", formData.attire);
      formdata.append("guest_count", formData.guestcount);
      formdata.append("venue_id", formData.eventvenue);
      const eventdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/book-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formdata,
      });
      const formatedData = await eventdata.json();
      console.log(formatedData);
      if (formatedData && formatedData.status) {
        handleSaveAndNext();
      } else {
        toast.error("Form is not submitted! Check your details.");
        console.log(formatedData.message);
      }
    } catch (err) {
      console.log("Error :" + err);
    } finally {
      setLoading(false);
    }
  };

  const eventEditSumbmit = async () => {
    setLoading(true);
    try {
      const formdata = new FormData();

      formdata.append("company_id", id);
      // if (formData.eventname) {
      //   formdata.append("event_name", formData.eventname);
      // }
      // if (formData.eventType) {
      //   formdata.append("event_type", formData.eventType);
      // }
      // if (formData.setuptime) {
      //   formdata.append("setup_time", formatDates(formData.setuptime));
      // }
      // if (formData.starttime) {
      //   formdata.append("start_date_time", formatDates(formData.starttime));
      // }
      // if (formData.endtime) {
      //   formdata.append("end_date_time", formatDates(formData.endtime));
      // }
      // if (formData.attire) {
      //   formdata.append("attire", formData.attire);
      // }
      // if (formData.guestcount) {
      //   formdata.append("guest_count", formData.guestcount);
      // }
      // if (formData.eventvenue) {
      //   formdata.append("venue_id", formData.eventvenue);
      // }
      formdata.append("package_id", formData.packageid);
      formdata.append("retainer_value", formData.retainerpaymentamount);
      if (formData.schpayment2) {
        formdata.append("scheduled_payment_2", formatDates(formData.schpayment2));
      }
      if (formData.sch2paymentamount) {
        formdata.append("scheduled_payment_2_value", formData.sch2paymentamount);
      }
      if (formData.schpayment3) {
        formdata.append("scheduled_payment_3", formatDates(formData.schpayment3));
      }
      if (formData.sch3paymentamount) {
        formdata.append("scheduled_payment_3_value", formData.sch3paymentamount);
      }
      const eventdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/book-event`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: formdata,
      });
      const formatedData = await eventdata.json();
      if (formatedData && formatedData.status) {
        showPopupMessege();
      } else {
        console.log(formatedData.message);
        toast.error(formatedData.message);
      }
    } catch (err) {
      console.log("Error :" + err);
    } finally {
      setLoading(false);
    }
  };
  const username = Cookies.get("username");
  const showPopupMessege = () => {
    Swal.fire({
      title: "Your DJ event booked successfully",
      html: `<span style="font-weight: 800">${username}</span> can visit your <a href=${process.env.REACT_APP_CLIENT_URL} target=_blank style="text-decoration: none;">Dashboard</a> to see full details about your DJ event <br/> Would you like to visit other DJ profiles or stay on <a href="/dj-profiles/specificDj/${id}" style="text-decoration: none;">${companyData?.company_name}</a> DJ profile?`,
      imageUrl: djImage,
      imageWidth: 450,
      imageHeight: 300,
      imageAlt: "djImage",
      confirmButtonText: "Visit DJ Profiles",
      cancelButtonText: "Stay DJ Profile",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-info",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        return navigate(`/dj-profiles/1`);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        navigate(`/dj-profiles/specificDj/${id}`); // Navigate to specific DJ profile if canceled
      }
    });
  };

  // Event-type / Categories function
  const handleEventType = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      eventType: value, // This line is correct and doesn't need changes
    }));
  };

  // Event-type / Categories function
  const handleAttire = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      attire: value, // This line is correct and doesn't need changes
    }));
  };

  // Event-type / Categories function
  const handleVenues = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      eventvenue: value, // This line is correct and doesn't need changes
    }));
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const handleSetEventDate = (date) => {
    // console.log("updatedDate :" + updatedDate);
    setFormData((prevState) => ({
      ...prevState,
      eventdate: date, // Update only the eventdate property
    }));
    setEventDateSelected(true);
  };

  const adjustTimeWithStartDate = (time, startDate) => {
    const adjustedTime = new Date(startDate);
    adjustedTime.setHours(time.getHours());
    adjustedTime.setMinutes(time.getMinutes());
    return adjustedTime;
  };

  const handlePackagePrice = (e) => {
    const value = e.target.value;
    if (value) {
      const selectedPackage = PackageData.data.find((item) => item.values === value);
      if (selectedPackage) {
        setFormData({
          ...formData,
          packageid: value,
          packageamount: selectedPackage.price,
          retainerpaymentamount: selectedPackage.price,
        });
      } else {
        console.log("Package not found");
        setFormData({
          ...formData,
          packageid: "",
          retainerpaymentamount: 0,
        });
      }
    } else {
      setFormData({
        ...formData,
        packageid: "",
        retainerpaymentamount: 0,
      });
    }
  };

  // Retainer Date & Amount Functions
  const handleSetRetainerData = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      eventdate: date, // Update only the eventdate property
    }));
  };
  const handleSetRetainerAmount = (e) => {
    const value = parseInt(e.target.value);
    const pkgValue = parseInt(formData.packageamount);
    const retainerValue = parseInt(formData.retainerpaymentamount);
    const sch2payment = parseInt(formData.sch2paymentamount);
    const sch3value = retainerValue + sch2payment;

    if (value >= pkgValue) {
      setFormData({
        ...formData,
        retainerpaymentamount: pkgValue,
        sch2paymentamount: 0,
      });
    } else if (value < pkgValue) {
      // const schValue = parseInt(formData.packageamount) - parseInt(value);
      const schValue = pkgValue - value;
      setFormData({
        ...formData,
        retainerpaymentamount: value,
        sch2paymentamount: schValue,
        sch3paymentamount: 0,
      });
    } else if (pkgValue === sch3value) {
      setFormData({ ...formData, sch3paymentamount: 0 });
    }
  };
  // scheduler-2 Date and Amount selection
  const handleSetSchData = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      schpayment2: date, // Update only the eventdate property
    }));
  };
  const handleSetSchAmount = (e) => {
    const value = parseInt(e.target.value);
    const pkgValue = parseInt(formData.packageamount);
    const retainerValue = parseInt(formData.retainerpaymentamount);

    let sch3paymentamount = 0;
    const schLastAmount = value + retainerValue;
    const schAmount = pkgValue - retainerValue;
    if (pkgValue === schLastAmount) {
      setFormData({ ...formData, sch3paymentamount: 0, sch2paymentamount: value });
      return;
    } else if (pkgValue > schLastAmount) {
      sch3paymentamount = pkgValue - schLastAmount;
      setFormData({ ...formData, sch2paymentamount: value, sch3paymentamount: sch3paymentamount });
    } else if (pkgValue < schLastAmount) {
      setFormData({
        ...formData,
        sch2paymentamount: schAmount,
        sch3paymentamount: 0,
      });
    }
  };
  // scheduler-3 Date and Amount selection
  const handleSetSchLastData = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      schpayment3: date, // Update only the eventdate property
    }));
  };
  const handleSetSchLastAmount = (e) => {
    const value = e.target.value;
  };

  const blockNumbers = (val) => {};

  return (
    <Content>
      <Block size="lg">
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5" className="page-title">
              Join Now to Book Your DJ
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Card className="border-1 p-3">
          <Nav tabs className="mx-3">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "1" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleIconTab("1");
                }}
              >
                <Icon name="layers" /> <span>Event Details</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                // disabled
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "2" })}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggleIconTab("2");
                }}
              >
                <Icon name="sign-dollar" /> <span>Financial Status</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeIconTab}>
            <TabPane tabId="1">
              <PreviewCard className="border-0">
                <Row className="gy-4">
                  <Col lg="6" className="border p-4 mt-0">
                    <Row className="my-2">
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Event Name
                            <Required />
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              required
                              className="form-control"
                              type="text"
                              id="default-0"
                              placeholder="Enter Event Name"
                              {...register("eventname", {
                                required: "This field is required",
                                pattern: {
                                  value: /^[A-Za-z\s]+$/,
                                  message: "Only alphabets and spaces are allowed",
                                },
                                minLength: {
                                  value: 2,
                                  message: "Please enter at least  characters",
                                },
                              })}
                              value={formData.eventname}
                              onChange={(e) => setFormData({ ...formData, eventname: e.target.value })}
                              maxLength={30}
                            />
                            {errors.eventname && <span className="invalid">{errors.eventname.message}</span>}
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Event Type <Required />
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              required
                              name="eventType"
                              id="eventType"
                              className="form-select"
                              value={formData.eventType}
                              onChange={(e) => handleEventType(e)}
                            >
                              {categoryListLoading ? (
                                <div className="mx-1 align-center">
                                  <Spinner size="sm" color="light" />
                                  <span className="px-1">Loading...</span>
                                </div>
                              ) : categoryListError ? (
                                <div>Error loading category. Please try again.</div>
                              ) : (
                                categoryList?.data?.Category &&
                                categoryList?.data?.Category.map((item, idx) => (
                                  <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                    {item?.title}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Attire
                          </Label>
                          <div className="form-control-wrap">
                            <select
                              name="attire"
                              id="attire"
                              className="form-select"
                              value={formData.attire}
                              onChange={(e) => handleAttire(e)}
                            >
                              {attireListLoading ? (
                                <div className="mx-1 align-center">
                                  <Spinner size="sm" color="light" />
                                  <span className="px-1">Loading...</span>
                                </div>
                              ) : attireListError ? (
                                <div>Error loading attire. Please try again.</div>
                              ) : (
                                attireList?.data?.packages &&
                                attireList?.data?.packages.map((item, idx) => (
                                  <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                    {item?.title}
                                  </option>
                                ))
                              )}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="g_count" className="form-label">
                            Guest Count
                          </Label>
                          <div className="form-control-wrap">
                            <input
                              className="form-control"
                              type="number"
                              id="g_count"
                              placeholder="Enter Guest Count"
                              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                              {...register("guestcount", {
                                required: "This is required",
                                maxLength: {
                                  value: 15,
                                  message: "You rich maximum guest count limit!",
                                },
                              })}
                              value={formData.guestcount}
                              onChange={(e) => {
                                if (e.target.value.length <= 5) {
                                  setFormData({ ...formData, guestcount: e.target.value });
                                } else {
                                  blockNumbers();
                                }
                              }}
                            />
                            {errors.guestcount && <span className="invalid">{errors.guestcount.message}</span>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" className="border p-4 mt-0">
                    <Row className="my-2">
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Event Date <Required />
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              required
                              placeholderText="Enter Event Date"
                              isClearable
                              selected={formData.eventdate} // Pass the eventdate state here
                              onChange={(date) => handleSetEventDate(date)} // Call the updated handler
                              dateFormat={"dd/MM/yy"}
                              className="form-control date-picker"
                              minDate={tomorrow}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormatCalendar="MMMM"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Setup Time
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              placeholderText="Enter Setup Time"
                              selected={formData.setuptime}
                              onChange={(time) => {
                                if (time == null) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    setuptime: "",
                                  }));
                                } else {
                                  const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    setuptime: adjustedTime,
                                  }));
                                }
                              }}
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                              showTimeSelect
                              showTimeSelectOnly
                              isClearable={eventDateSelected}
                              disabled={!eventDateSelected}
                              name="setuptime"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Start Time <Required />
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              required
                              minTime={
                                formData.setuptime
                                  ? new Date(new Date(formData.setuptime).getTime() + 15 * 60 * 1000)
                                  : new Date().setHours(0, 0, 0, 0)
                              }
                              maxTime={new Date().setHours(23, 59, 59, 999)}
                              placeholderText=" Select Start Time"
                              selected={formData.starttime}
                              onChange={(time) => {
                                if (time == null) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    starttime: "",
                                  }));
                                } else {
                                  const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    starttime: adjustedTime,
                                  }));
                                }
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                              isClearable={eventDateSelected}
                              disabled={!eventDateSelected}
                              name="starttime"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            End Time <Required />
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              required
                              minTime={
                                formData.starttime
                                  ? new Date(new Date(formData.starttime).getTime() + 15 * 60 * 1000)
                                  : new Date().setHours(0, 0, 0, 0)
                              }
                              maxTime={new Date().setHours(23, 59, 59, 999)}
                              placeholderText=" Select End Time"
                              selected={formData.endtime}
                              onChange={(time) => {
                                if (time == null) {
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    endtime: "",
                                  }));
                                } else {
                                  const adjustedTime = adjustTimeWithStartDate(time, formData.eventdate);
                                  setFormData((prevState) => ({
                                    ...prevState,
                                    endtime: adjustedTime,
                                  }));
                                }
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                              isClearable={eventDateSelected}
                              disabled={!eventDateSelected}
                              name="endtime"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" className="border px-4 mt-0">
                    <div className="form-group my-4">
                      <Label htmlFor="default-1" className="form-label">
                        Selected Venue <Required />
                      </Label>
                      <div className="form-control-wrap w-50">
                        <div className="form-control-select">
                          <select
                            required
                            name="eventvenue"
                            id="eventvenue"
                            className="form-select"
                            value={formData.eventvenue}
                            onChange={(e) => handleVenues(e)}
                          >
                            {venueListLoading ? (
                              <div className="mx-1 align-center">
                                <Spinner size="sm" color="light" />
                                <span className="px-1">Loading...</span>
                              </div>
                            ) : venueListError ? (
                              <div>Error loading venues. Please try again.</div>
                            ) : (
                              venueList?.data?.list &&
                              venueList?.data?.list.map((item, idx) => (
                                <option key={idx} value={item.id} className=" p-0 m-0 my-1 p-1 rounded-0">
                                  {item?.venue_name}
                                </option>
                              ))
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </PreviewCard>
            </TabPane>
            <TabPane tabId="2">
              <PreviewCard className="border-0">
                <Row className="gy-4">
                  <Col lg="6" className="border p-4 mt-0">
                    <Row className="my-2">
                      <Col sm="8">
                        <div className="form-group">
                          <Label htmlFor="default-0" className="form-label">
                            Package Name
                          </Label>
                          <div className="form-control-wrap">
                            <div className="form-control-select">
                              <select
                                name="eventpackage"
                                id="eventpackage"
                                className="form-select"
                                value={formData.packageid}
                                onChange={handlePackagePrice}
                              >
                                {PackageData.data.map((item, idx) => (
                                  <option key={idx} value={item.values} className="fs-6 p-0 m-0 my-1 p-1 rounded-0">
                                    {item?.steps}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Selected Package Amount
                          </Label>
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <input
                                className="form-control fw-normal"
                                type="text"
                                id="default-0"
                                placeholder="0"
                                disabled
                                value={formData.packageamount}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 mt-3">
                      <Col sm="8">
                        <div className="form-group align-center h-100">
                          <Label htmlFor="default-1" className="form-label mb-0">
                            The retainer amount depends on your package. You can adjust the retainer amount according to
                            the scheduler date amount provided below.
                          </Label>
                          {/* <div className="form-control-wrap">
                            <DatePicker
                              isClearable
                              required
                              selected={formData.retainer}
                              onChange={handleSetRetainerData}
                              dateFormat={"dd/MM/yy"}
                              minDate={tomorrow}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormatCalendar="MMMM"
                              className="form-control date-picker"
                              placeholderText="Enter Retainer Date"
                            />{" "}
                          </div> */}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Amount
                          </Label>
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="0"
                                value={formData.retainerpaymentamount}
                                onChange={handleSetRetainerAmount}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 mt-3">
                      <Col sm="8">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Scheduled Payment - 2
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              isClearable
                              selected={formData.schpayment2}
                              onChange={(date) => handleSetSchData(date)}
                              dateFormat={"dd/MM/yy"}
                              minDate={tomorrow}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormatCalendar="MMMM"
                              className="form-control date-picker"
                              placeholderText="Enter Scheduler-2 Date"
                            />{" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Amount
                          </Label>
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="0"
                                value={formData.sch2paymentamount}
                                onChange={(e) => handleSetSchAmount(e)}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 mt-3">
                      <Col sm="8">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Scheduled Payment - 3
                          </Label>
                          <div className="form-control-wrap">
                            <DatePicker
                              isClearable
                              selected={formData.schpayment3}
                              onChange={(date) => handleSetSchLastData(date)}
                              dateFormat={"dd/MM/yy"}
                              minDate={tomorrow}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              dateFormatCalendar="MMMM"
                              className="form-control date-picker"
                              placeholderText="Enter Scheduler-3 Date"
                            />{" "}
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="form-group">
                          <Label htmlFor="default-1" className="form-label">
                            Amount
                          </Label>
                          <div className="form-control-wrap">
                            <div className="input-group">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="0"
                                value={formData.sch3paymentamount}
                                onChange={(e) => handleSetSchLastAmount(e)}
                                disabled
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  $
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6" className="border p-4 mt-0">
                    <Card className="border-0 my-2">
                      <Label htmlFor="default-0" className="form-label">
                        Fee Summary
                      </Label>
                      <Block className="border-0 m-0">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>
                                {/* {basicData.header.map((item, idx) => {
                                return <th key={idx}>{item}</th>;
                                })} */}
                                Item
                              </th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {PackageData.data.find((item) => item.values === String(formData.packageid))?.steps ||
                                  ""}{" "}
                                Package
                              </td>
                              {selectedPackage && <td>{selectedPackage}</td>}
                            </tr>
                            <tr>
                              <td>Retainer Amount</td>
                              <td>{formData.retainerpaymentamount}</td>
                            </tr>
                            <tr>
                              <td>Balance Due</td>
                              <td>
                                {formData.sch2paymentamount && formData.sch3paymentamount
                                  ? parseInt(formData.sch2paymentamount) + parseInt(formData.sch3paymentamount)
                                  : formData.sch2paymentamount
                                  ? parseInt(formData.sch2paymentamount) + 0
                                  : 0}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Block>
                    </Card>
                  </Col>
                </Row>
              </PreviewCard>
            </TabPane>
          </TabContent>
        </Card>
        <div className={activeIconTab === "1" ? "d-flex justify-end mb-2 mt-3" : "d-flex justify-between mb-2 mt-3"}>
          {activeIconTab !== "1" && (
            <Button color="primary" onClick={() => setActiveIconTab(back.toString())}>
              Previous
            </Button>
          )}
          {loading ? (
            <Button disabled color="primary">
              <Spinner size="sm" />
              <span> Loading... </span>
            </Button>
          ) : (
            <Button color="primary" onClick={activeIconTab === "1" ? eventSumbmit : eventEditSumbmit}>
              {activeIconTab === "2" ? "Save" : "Save & Next"}
            </Button>
          )}
        </div>
      </Block>
    </Content>
  );
};

export default DJBookingEvent;
