import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import PlaceHolderImage from "../../images/Profile_Picture.png";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Link } from "react-scroll";

const ListView = ({ profiles, companiesListLoading }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-3 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
      {companiesListLoading ? (
        <div className="d-flex flex-column justify-center align-center">
          {Array.from({ length: 5 }).map((_, index) => (
            <ShimmerContentBlock title text cta thumbnailWidth={100} thumbnailHeight={100} />
          ))}
        </div>
      ) : (
        <div>
          {profiles.map((profile) => (
            <Row
              style={{ cursor: "pointer" }}
              key={profile?.id}
              className="my-2 border g-0 rounded-start-5 shawdow leaflet-bar"
              onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
            >
              <Col lg={4} sm={6} xs={6}className="px-0">
                <div className="rounded-start-5">
                  <img
                    src={profile?.profile_image_path || PlaceHolderImage}
                    alt={profile?.company_name || "----"}
                    className="img-fluid object-fit-cover rounded-start-5"
                    style={{ height: "300px", width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={8} sm={6} xs={6}className="d-flex flex-column justify-content-center px-0">
                <div className="px-3">
                  <h5 className="fw-bold mb-0 mb-md-2 fs-2 d-md-block d-none">{profile?.company_name || "----"}</h5>
                  <h5 className="fw-bold mb-1 fs-4 d-md-none">{profile?.company_name || "----"}</h5>

                  <p className="text-muted fs-3 mb-0 mb-md-2 fw-medium d-md-block d-none">
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>
                  <p className="text-muted fs-5 mb-1 fw-medium d-md-none">
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>

                  <p className="fs-5 text-secondary d-md-block d-none" style={{ height: "90px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 230 ? (
                      <>
                        {profile?.uniqueness.substring(0, 230)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <p className="fs-6 text-secondary d-md-none mb-2" style={{ height: "90px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 80 ? (
                      <>
                        {profile?.uniqueness.substring(0, 80)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <div className="row mx-1 w-100">
                    <div className="d-flex align-center col-6 p-0 py-1">
                      <p className="fs-5 d-md-block d-none">
                        Starting from: ${profile?.max_packages?.[0]?.min_package_price || 0}
                      </p>
                      <p className="fs-6 d-md-none">
                        Starting from: ${profile?.max_packages?.[0]?.min_package_price || 0}
                      </p>
                    </div>
                    <div className="col-6 p-0 justify-end align-center">
                      <Button
                        color="primary"
                        className="p-0 h-75 px-3 pt-3 pb-3"
                        onClick={() => navigate(`/dj-profiles/specificDj/${profile?.id}`)}
                      >
                        View DJ Profile
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListView;
