import React, { useContext, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Block, BlockTitle, Col, Icon, PreviewCard, Row } from "../../components/Component";
import Content from "../../layout/content/Content";
import { Card, Nav, NavItem } from "reactstrap";
import { Link } from "react-scroll";
import FAQ from "../Individual DJ Pages/FAQ";
import HeroSectionImage from "../../images/DJ_Section2.png";
import { NavLink } from "react-router-dom";
import { createcontext } from "../../layout/Index";

const ClientTimeLine = () => {
  const { heroSection, setHeroSection } = useContext(createcontext);

  useEffect(() => {
    setHeroSection(true);
  }, []);
  return (
    <>
      <Content>
        <BlockTitle page>
          <h3>
            Enhance Your Event Experience with <span className="text-primary fw-medium">I Party DJ MIX</span>
          </h3>
        </BlockTitle>
        <PreviewCard className="my-2">
          <Row>
            {/* <Col lg={6}>
              <div>
                <img src={HeroSectionImage} alt="DJ Hero Section Image" className="img-fluid" />
              </div>
            </Col> */}
            <Col>
              <Block className="border-0 px-5">
                <Card className="p-3 fs-5  align-center">
                  <BlockTitle className="my-2">How Do I Set Up My I Party DJ MIX Account?</BlockTitle>
                  <div className="my-2">
                    <p>
                      Start by visiting the{" "}
                      <NavLink to={`${process.env.REACT_APP_PUBLIC_URL}`}>
                        <span className="text-primary fw-medium" style={{ cursor: "pointer" }}>
                          I Party DJ MIX
                        </span>{" "}
                      </NavLink>{" "}
                      website and clicking on 'Sign In'. Complete the registration form with your personal details and
                      verify your email address to begin browsing and booking DJs for your events. Start by going to the{" "}
                    </p>
                  </div>
                  <div className="my-1">
                    <Nav tabs style={{ cursor: "pointer" }} className="border-0">
                      <NavItem className="p-0">
                        <NavLink
                          to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=client`}
                          spy={true}
                          className="fw-medium fs-5 btn btn-primary"
                        >
                          Sign In
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Card>
              </Block>
            </Col>
          </Row>
        </PreviewCard>
        <div id="DjReg" className="my-2">
          <PreviewCard>
            <BlockTitle className="my-3 text-center">4 Simple Steps to Find the Perfect DJ for Your Event</BlockTitle>
            <Block>
              <VerticalTimeline lineColor={"#364a63"}>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">1</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Create an Account
                  </h3>
                  <p>Sign up on I Party DJ MIX with your basic details to start exploring DJ options.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">2</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Complete Your Preferences
                  </h3>
                  <p> Fill in your event details, such as the date, location, and type of DJ you're looking for.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">3</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Browse DJ Profiles
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle"></h4>
                  <p>
                    Explore a range of DJs, review their profiles, services, and experience to find the perfect fit for
                    your event.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">4</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Book Your DJ
                  </h3>
                  <p>
                    Finalize your choice and book the DJ for your event. Enjoy seamless communication and secure payment
                    options.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={
                    <Icon name="arrow-down-round-fill" className="fs-1 justify-content-center align-center h-100" />
                  }
                />
              </VerticalTimeline>
              <h3 className="vertical-timeline-element-title text-center fs-3 my-2">
                Rock Your Event With <span className="text-primary fw-medium">I Party DJ MIX</span>
              </h3>
              <h3 className="vertical-timeline-element-title text-center fs-4 fw-normal my-3">
                <NavLink
                  to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=client`}
                  className="btn btn-primary text-white fs-5 p-2 px-3"
                >
                  Create Your Client Profile
                </NavLink>
              </h3>
            </Block>
          </PreviewCard>
        </div>
        <PreviewCard>
          <Row className="justify-around my-3 mx-2">
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    100+
                  </span>
                  <span className="text-white fw-medium fs-5">Professional DJ Companies</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    1000+
                  </span>
                  <span className="text-white fw-medium fs-5">Powerful Client Network</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    5000+
                  </span>
                  <span className="text-white fw-medium fs-5">Events Booked by Our Clients</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    100%
                  </span>
                  <span className="text-white fw-medium fs-5">Trust Worthy Platform</span>
                </div>
              </div>
            </Col>
          </Row>
        </PreviewCard>
        <div className="my-2">
          <FAQ />
        </div>
      </Content>
    </>
  );
};

export default ClientTimeLine;
