import React from "react";
import { Block, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import { Badge, Card, Col, Progress, Row, Spinner } from "reactstrap";
import Content from "../../layout/content/Content";
import { getReview } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { useParams } from "react-router";

const Review = ({ companyData }) => {
  const { id } = useParams();
  const {
    data: reviewData,
    isLoading: reviewDataLoading,
    isError: reviewDataError,
  } = useQuery({
    queryKey: ["get-review-list", id],
    queryFn: () => getReview({ id: id }),
    keepPreviousData: true,
  });
  return (
    <div id="review" className="mb-5">
      <Content>
        <BlockTitle page className="mb-4 text-center" tag="h3">
          Reviews
        </BlockTitle>
        <Row className="border h-500px mb-1">
          <Col lg="5" className="justify-center">
            <div className="d-flex gy-3 w-400px my-3">
              <div className="progress-amount me-4">
                <h1 className="title">{reviewData?.data?.review ? reviewData.data.review.length + ".0" : "0.0"}</h1>
                <ul className="rating">
                  <li>
                    <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                  </li>
                  <li>
                    <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                  </li>
                  <li>
                    <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                  </li>
                  <li>
                    <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                  </li>
                  <li>
                    <Icon name={reviewData?.data?.review ? "star-fill" : "star"}></Icon>
                  </li>
                </ul>
                <span className="sub-text mt-1">
                  <Icon name="users-fill"></Icon>{" "}
                  {reviewData?.data?.review ? reviewData.data.review.length + " Total" : "0 Total"}
                </span>
              </div>
              {reviewData?.data?.review ? (
                <div className="rating-progress-bar gy-1 w-100">
                  <div className="progress-rating">
                    <div className="progress-rating me-2">5</div>
                    <Progress value={100} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">4</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">3</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">2</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">1</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                </div>
              ) : (
                <div className="rating-progress-bar gy-1 w-100">
                  <div className="progress-rating">
                    <div className="progress-rating me-2">5</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">4</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">3</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">2</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">1</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col lg="7" className="overflow-auto h-475px" style={{ scrollbarWidth: "none" }}>
            {reviewDataLoading ? (
              <div className="mx-1 align-center">
                <Spinner size="sm" color="light" />
                <span className="px-1">Loading...</span>
              </div>
            ) : reviewDataError ? (
              <div>Error loading reviews. Please try again.</div>
            ) : reviewData?.data?.review ? (
              reviewData?.data?.review.map((name, index) => (
                <div className="card-inner py-3" key={index}>
                  <div className="d-flex ">
                    <div className={`user-avatar ${index % 2 === 0 ? "bg-purple" : "bg-info"} me-3`}>
                      <span>{name.client_name.charAt(0).toUpperCase()}</span>
                    </div>
                    <div className="fake-class">
                      <div className="d-flex justify-between mt-1">
                        <div className="">
                          <h5 className="mt-0 d-flex flex-column">
                            <span>{name.client_name}</span>
                            <span className="fw-light fs-6">{name.designation}</span>
                          </h5>
                        </div>
                        <ul className="rating mt-0 mb-2">
                          {name.rating && (
                            <li>
                              {/* Filled stars */}
                              {[...Array(parseInt(name.rating))].map((_, index) => (
                                <Icon name="star-fill" key={index}></Icon>
                              ))}
                              {/* Empty stars */}
                              {[...Array(5 - parseInt(name.rating))].map((_, index) => (
                                <Icon name="star-outline" key={index + parseInt(name.rating)}></Icon>
                              ))}
                            </li>
                          )}
                        </ul>
                      </div>
                      <h6 className="mt-2 d-flex align-center mb-0 mb-1">
                        <span>{name.title}</span>
                      </h6>
                      <p className="text-soft mb-0">{name.review}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="h-100 align-center justify-center">
                <p className="text-muted fs-2 fw-light">No reviews available</p>
              </div>
            )}
          </Col>
        </Row>
        {/* {id === "6" ? (
          <Row className="border h-500px mb-1">
            <Col lg="5" className="justify-center">
              <div className="d-flex gy-3 w-400px my-3">
                <div className="progress-amount me-4">
                  <h1 className="title">5.0</h1>
                  <ul className="rating">
                    <li>
                      <Icon name="star-fill"></Icon>
                    </li>
                    <li>
                      <Icon name="star-fill"></Icon>
                    </li>
                    <li>
                      <Icon name="star-fill"></Icon>
                    </li>
                    <li>
                      <Icon name="star-fill"></Icon>
                    </li>
                    <li>
                      <Icon name="star-fill"></Icon>
                    </li>
                  </ul>
                  <span className="sub-text mt-1">
                    <Icon name="users-fill"></Icon> 5 Total
                  </span>
                </div>
                <div className="rating-progress-bar gy-1 w-100">
                  <div className="progress-rating">
                    <div className="progress-rating me-2">5</div>
                    <Progress value={100} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">4</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">3</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">2</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">1</div>
                    <Progress value={0} color="primary" className="progress-lg"></Progress>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="7"
              className={`${
                reviewData?.data?.review.length === 0
                  ? "d-flex justify-content-center align-items-center"
                  : "overflow-auto h-475px"
              }`}
              style={{ scrollbarWidth: "none" }}
            >
              {reviewDataLoading ? (
                <div className="mx-1 align-center">
                  <Spinner size="sm" color="light" />
                  <span className="px-1">Loading...</span>
                </div>
              ) : reviewDataError ? (
                <div>Error loading reviews. Please try again.</div>
              ) : reviewData?.data?.review ? (
                reviewData?.data?.review.map((name, index) => (
                  <div className="card-inner py-3" key={index}>
                    <div className="d-flex ">
                      <div className={`user-avatar ${index % 2 === 0 ? "bg-purple" : "bg-info"} me-3`}>
                        <span>{name.client_name.charAt(0).toUpperCase()}</span>
                      </div>
                      <div className="fake-class">
                        <div className="d-flex justify-between mt-1">
                          <div className="">
                            <h5 className="mt-0 d-flex flex-column">
                              <span>{name.client_name}</span>
                              <span className="fw-light fs-6">{name.designation}</span>
                            </h5>
                          </div>
                          <ul className="rating mt-0 mb-2">
                            {name.rating && (
                              <li>
                                Filled stars
                                {[...Array(parseInt(name.rating))].map((_, index) => (
                                  <Icon name="star-fill" key={index}></Icon>
                                ))}

                                Empty stars
                                {[...Array(5 - parseInt(name.rating))].map((_, index) => (
                                  <Icon name="star-outline" key={index + parseInt(name.rating)}></Icon>
                                ))}
                              </li>
                            )}
                          </ul>
                        </div>
                        <h6 className="mt-2 d-flex align-center mb-0 mb-1">
                          <span>{name.title}</span>
                        </h6>
                        <p className="text-soft mb-0">{name.review}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Reviews Found</div>
              )}
            </Col>
          </Row>
        ) : (
          <Row className="border h-500px mb-1">
            <Col lg="5" className="justify-center">
              <div className="d-flex gy-3 w-400px my-5">
                <div className="progress-amount me-4">
                  <h1 className="title">0.0</h1>
                  <ul className="rating">
                    <li>
                      <Icon name="star"></Icon>
                    </li>
                    <li>
                      <Icon name="star"></Icon>
                    </li>
                    <li>
                      <Icon name="star"></Icon>
                    </li>
                    <li>
                      <Icon name="star"></Icon>
                    </li>
                    <li>
                      <Icon name="star"></Icon>
                    </li>
                  </ul>
                  <span className="sub-text mt-1">
                    <Icon name="users-fill"></Icon> 0 Total
                  </span>
                </div>
                <div className="rating-progress-bar gy-1 w-100">
                  <div className="progress-rating">
                    <div className="progress-rating me-2">5</div>
                    <Progress value={0} color="Primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">4</div>
                    <Progress value={0} color="Primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">3</div>
                    <Progress value={0} color="Primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">2</div>
                    <Progress value={0} color="Primary" className="progress-lg"></Progress>
                  </div>
                  <div className="progress-rating">
                    <div className="progress-rating me-2">1</div>
                    <Progress value={0} color="Primary" className="progress-lg"></Progress>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="7" className="d-flex justify-content-center align-items-center">
              <div className="h-100 align-center justify-center">
                <p className="text-muted fs-2 fw-light">No reviews available</p>
              </div>
            </Col>
          </Row>
        )} */}
      </Content>
    </div>
  );
};

export default Review;
