import React, { useContext, useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import HeroSection from "./HeroSection";
import TopDjs from "./TopDjs";
import { Card, Col, Row } from "reactstrap";
import FAQ from "../Individual DJ Pages/FAQ";
import DjSection from "../DJ Section/ClientSection";
import RoleSection from "../Individual DJ Pages/RoleSection";
import ClientSection from "../DJ Section/ClientSection";
import { createcontext } from "../../layout/Index";

const HomePage = () => {
  const { heroSection, setHeroSection } = useContext(createcontext);
  useEffect(() => {
    setHeroSection(false);
  }, []);
  return (
    <>
      <Head title="Default Dashboard" />
      <section>
        <HeroSection heroSection={heroSection} setHeroSection={setHeroSection} />
        {heroSection ? (
          <>
            <TopDjs />
            <Row className="g-0">
              <Col xxl="12" className="m-0">
                <Card>
                  <ClientSection />
                </Card>
                <Card className="card-bordered mt-0">
                  <FAQ />
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default HomePage;
