import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppMain from "./global/AppMain";
import AppRoot from "./global/AppRoot";
import AppHeader from "./header/AppHeader";
import Head from "./head/Head";
import Footer from "./footer/Footer";
import { getCookie } from "../utils/Utils";

export const createcontext = createContext();
const Layout = ({ title, app }) => {
  const [heroSection, setHeroSection] = useState(false);
  useEffect(() => {
    const heroCokkie = getCookie("Herosection");

    if (heroCokkie === "true") {
      setHeroSection(true);
    } 
    else {
      setHeroSection(false);
    }
  }, [heroSection]);
  return (
    <>
      <Head title={title || "Loading..."} />
      <AppRoot>
        <createcontext.Provider value={{ heroSection, setHeroSection }}>
          <AppMain>
            <AppHeader app={app} className="sticky-top" />
            <Outlet />
            <Footer />
          </AppMain>
        </createcontext.Provider>
      </AppRoot>
    </>
  );
};

export default Layout;
