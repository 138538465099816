import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import ListView from "./ListView";
import ImagesView from "./ImagesView";
import Icon from "../../components/icon/Icon";
import MapView from "./MapView";
import PaginationComponent from "../../components/pagination/Pagination";
import { getAllCompanies } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";

const ProfileList = ({
  fetchedCompaniesList,
  currentPage,
  setCurrentPage,
  companiesListLoading,
  handlePageChange,
  profilesPerPage,
  dJCompany,
  setMapView,
}) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [view, setView] = useState("list");
  const [loading, setLoading] = useState(false); // To manage loading state
  const [error, setError] = useState(null); // To manage error state

  useEffect(() => {
    if (fetchedCompaniesList?.data) {
      const parsedData = fetchedCompaniesList.data.map((profile) => ({
        ...profile,
        images: profile.images ? JSON.parse(profile.images) : {},
      }));
      setData(parsedData);
      setTotalCount(fetchedCompaniesList.total);

      if (view !== "map") {
        setTotalPages(Math.ceil(fetchedCompaniesList.total / profilesPerPage));
      } else {
        setTotalPages(1);
      }
    }
  }, [fetchedCompaniesList, profilesPerPage, view]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage, setCurrentPage]);

  const handleViewChange = async (viewType) => {
    setView(viewType);
    setCurrentPage(1);
    setMapView(viewType);

    if (viewType === "map") {
      try {
        setLoading(true);
        setError(null);

        const companies = await getAllCompanies({
          type: "",
        });

        const parsedData = companies.data.map((profile) => ({
          ...profile,
          images: profile.images ? JSON.parse(profile.images) : {},
        }));

        setData(parsedData);
        setTotalCount(companies.total);
        setLoading(false);
      } catch (error) {
        setError("Failed to load companies data");
        setLoading(false);
      }
    }
    setOpen(false);
  };

  const handleViewMap = (viewType) => {
    setView(viewType);
    setMapView(viewType);
  };

  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="px-0">
      <div
        style={view === "map" ? { position: "absolute", top: "67px", zIndex: "9999", right: "-5px" } : {}}
        className={view === "map" ? "d-flex justify-content-between my-2 mx-1" : "d-flex justify-content-between mx-1"}
      >
        {view === "map" ? (
          <h5 className={view === "map" ? "d-none" : "align-center m-0 mx-4"}>Select Nearby DJ's</h5>
        ) : (
          <h5 className="align-center m-0">
            {totalCount || 0} {totalCount <= 1 ? "Result" : "Results"}
          </h5>
        )}
        <div className="d-flex gx-2">
          <div className="d-md-none">
            <Dropdown isOpen={open} toggle={toggle}>
              <DropdownToggle
                tag="a"
                href="#dropdown"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle nk-quick-nav-icon"
              >
                <div className="icon-status icon-status-na">
                  <Icon name="more-h"></Icon>
                </div>
              </DropdownToggle>
              <DropdownMenu end className="w-min-120px">
                <div className="d-flex flex-column align-center">
                  <span className="my-1 d-flex" style={{ width: "110px" }} onClick={() => handleViewChange("list")}>
                    <Badge
                      color="outline-primary"
                      className={
                        view == "list"
                          ? "fs-5 p-1 rounded-3 justify-center text-primary w-100"
                          : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="list-round" className="me-1"></Icon>
                      List
                    </Badge>
                  </span>
                  <span className="my-1" style={{ width: "110px" }} onClick={() => handleViewChange("images")}>
                    <Badge
                      color="outline-primary"
                      className={
                        view == "images"
                          ? "fs-5 p-1 rounded-3 justify-center text-primary w-100"
                          : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="cards" className="me-1"></Icon>
                      Image
                    </Badge>
                  </span>
                  <span className="my-1" style={{ width: "110px" }} onClick={() => handleViewChange("map")}>
                    <Badge
                      color="outline-primary"
                      className={
                        view == "map"
                          ? "fs-5 p-1 rounded-3 text-primary w-100 justify-center"
                          : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="map-pin" className="me-1"></Icon>
                      Map
                    </Badge>
                  </span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className="d-md-block d-none">
            <ButtonGroup className="me-4">
              <Button
                color={view === "list" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                <Icon name="list-round" className="me-1 fs-4" />
                List
              </Button>
              <Button
                color={view === "images" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "images" ? "active" : ""}`}
                onClick={() => handleViewChange("images")}
              >
                <Icon name="cards" className="me-1 fs-4" />
                Images
              </Button>
              <Button
                color={view === "map" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "map" ? "active" : ""}`}
                onClick={() => handleViewChange("map")}
              >
                <Icon name="map-pin" className="me-1 fs-4" />
                Map
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <ShimmerThumbnail height={630} width={1500} className="m-2" rounded />
        </>
      ) : error ? (
        <div>{error}</div>
      ) : view === "map" ? (
        <MapView profiles={data} city={data?.[0]?.city} />
      ) : view === "images" ? (
        <div className="mb-3 p-2 rounded">
          {totalCount > 0 ? (
            <div>
              <ImagesView profiles={data} companiesListLoading={companiesListLoading} />
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? null : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
      ) : view === "list" ? (
        <div className="mb-3 p-2 rounded">
          {totalCount > 0 ? (
            <>
              <ListView profiles={data} companiesListLoading={companiesListLoading} dJCompany={dJCompany} />
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? null : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileList;
