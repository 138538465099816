import React, { useContext, useEffect } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Block, BlockTitle, Col, Icon, PreviewCard, Row } from "../../components/Component";
import Content from "../../layout/content/Content";
import { Card, Nav, NavItem } from "reactstrap";
import { Link } from "react-scroll";
import HeroSectionImage from "../../images/DJ_Section2.png";
import { NavLink } from "react-router-dom";
import FAQ_DJ from "../Individual DJ Pages/FAQ_DJ";
import { createcontext } from "../../layout/Index";

const DjTimeLine = () => {
  const { heroSection, setHeroSection } = useContext(createcontext);
  useEffect(() => {
    setHeroSection(true);
  }, []);
  return (
    <>
      <Content>
        <BlockTitle page>
          <h3>
            Enhance Your DJ Business with <span className="text-primary fw-medium">I Party DJ MIX</span>
          </h3>
        </BlockTitle>
        <PreviewCard className="my-2">
          <Row>
            {/* <Col lg={6}>
              <div>
                <img src={HeroSectionImage} alt="DJ Hero Section Image" className="img-fluid" />
              </div>
            </Col> */}
            <Col>
              <Block className="border-0 px-5">
                <Card className="p-4 fs-5 align-center">
                  <BlockTitle className="my-2">How Do I Set Up My I Party DJ MIX Account?</BlockTitle>
                  <div className="my-2">
                    <p>
                      Start by going to the{" "}
                      <NavLink to={`${process.env.REACT_APP_PUBLIC_URL}`}>
                        <span className="text-primary fw-medium" style={{ cursor: "pointer" }}>
                          I Party DJ MIX
                        </span>{" "}
                      </NavLink>
                      website and selecting 'Sign In'. Complete the registration form with your business details and
                      verify your email address.
                    </p>
                  </div>
                  <div className="my-1">
                    <Nav tabs style={{ cursor: "pointer" }} className="border-0">
                      <NavItem className="p-0">
                        <NavLink
                          to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`}
                          spy={true}
                          className="fw-medium fs-5 btn btn-primary"
                        >
                          Sign In
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Card>
              </Block>
            </Col>
          </Row>
        </PreviewCard>
        <div id="DjReg" className="my-2">
          <PreviewCard>
            <BlockTitle className="my-3 text-center">4 Simple Steps To Create Your DJ Company</BlockTitle>
            <Block>
              <VerticalTimeline lineColor={"#364a63"}>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">1</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Create an Account
                  </h3>
                  <p>Sign up on I Party DJ MIX with your basic details to get started.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">2</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Complete Your Profile
                  </h3>
                  <p> Fill in your DJ company's information, including services, experience, and contact details.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">3</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Enjoy 7 Days Free
                  </h3>
                  <h4 className="vertical-timeline-element-subtitle"></h4>
                  <p>Take advantage of a 7-day free trial to explore the platform and its features.</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={<span className="fs-1 justify-content-center align-center h-100">4</span>}
                >
                  <h3 className="vertical-timeline-element-title fs-3" style={{ color: "#ff1300" }}>
                    Launch Your Profile
                  </h3>
                  <p>Finalize and publish your profile to start attracting clients on the platform.</p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                  iconStyle={{ background: "#ff1300", color: "#fff", border: "3px solid #364a63", boxShadow: "none" }}
                  icon={
                    <Icon name="arrow-down-round-fill" className="fs-1 justify-content-center align-center h-100" />
                  }
                />
              </VerticalTimeline>
              <h3 className="vertical-timeline-element-title text-center fs-3 my-2">
                Start Journey With <span className="text-primary fw-medium">I Party DJ MIX</span>
              </h3>
              <h3 className="vertical-timeline-element-title text-center fs-4 fw-normal my-3">
                <NavLink
                  to={`${process.env.REACT_APP_ACCOUNT_URL}register?type=business`}
                  className="btn btn-primary text-white fs-5 p-2 px-3"
                >
                  Create Your DJ Profile
                </NavLink>
              </h3>
            </Block>
          </PreviewCard>
        </div>
        <PreviewCard>
          <Row className="justify-around my-3 mx-2">
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    100+
                  </span>
                  <span className="text-white fw-medium fs-5">Professional DJ Companies</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    1000+
                  </span>
                  <span className="text-white fw-medium fs-5">Powerful Client Network</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    5000+
                  </span>
                  <span className="text-white fw-medium fs-5">Events Booked by Our Clients</span>
                </div>
              </div>
            </Col>
            <Col lg={3} className="justify-center my-1">
              <div className="carduidjsection">
                <div className="d-flex flex-column justify-center align-center h-100 p-3">
                  <span className="fw-medium lead-text" style={{ color: "#00ff58", fontSize: "40px" }}>
                    100%
                  </span>
                  <span className="text-white fw-medium fs-5">Trust Worthy Platform</span>
                </div>
              </div>
            </Col>
          </Row>
        </PreviewCard>
        <div className="my-2">
          <FAQ_DJ />
        </div>
      </Content>
    </>
  );
};

export default DjTimeLine;
