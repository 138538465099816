import React, { useState } from "react";
import { Alert, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { getCompanyFeaturePerformance } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { ShimmerButton, ShimmerThumbnail } from "react-shimmer-effects";
import placeHolderImg from "../../images/Profile_Picture.png";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NoResult from "../../images/No Result.png";
import vidDJ from "../../images/vidDJ.mp4";

const TopDjs = () => {
  const navigate = useNavigate();

  const {
    data: featuredDJs,
    isLoading: featuredDJsDjsLoading,
    isError: featuredDJsDjsError,
  } = useQuery({
    queryKey: ["get-featuredDJsDjs"],
    queryFn: () => getCompanyFeaturePerformance({ type: 10 }),
  });
  return (
    <>
      <Card className="border mt-0">
        <div className="pt-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
            <h2 className="fs-2 lead-text mb-3">Featured DJ Companies</h2>
            <p className="lead text-muted">
              Explore these renowned DJ companies, celebrated for their outstanding talent, expertise, and commitment to
              delivering top-tier entertainment. Each company brings a unique blend of professionalism and creativity,
              ensuring your event is memorable and engaging. Whether you're planning a wedding, corporate event, or
              private party, these DJ companies offer a wide range of services tailored to fit your specific needs. Dive
              into their profiles to discover the perfect match that aligns with your vision, and trust them to elevate
              your next celebration with high-quality sound, seamless transitions, and an unforgettable atmosphere.
            </p>
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Row>
              <div className="mb-4">
                {featuredDJs?.status === false ? (
                  <Col xs="12">
                    <Alert color="white" className="text-center">
                      <h4 className="alert-heading">Oops! Something went wrong.</h4>
                      <p>
                        We're sorry, but we couldn't get the Featured DJs for you at the moment. Please try again later
                        or click the button below to refresh the page and try again.
                      </p>
                      <Button color="primary" onClick={() => window.location.reload()}>
                        Refresh
                      </Button>
                    </Alert>
                  </Col>
                ) : (
                  <Card style={{ backgroundColor: "#F8F9FA" }}>
                    {featuredDJs?.data?.Platinum.length < 0 ? (
                      <div className="d-flex justify-center align-center h-100px fs-5">No Data Found</div>
                    ) : (
                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className="p-0 m-0 "
                        containerClass=""
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1440,
                            },
                            items: 4,
                            partialVisibilityGutter: 40,
                          },
                          mobile: {
                            breakpoint: {
                              max: 600,
                              min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 600,
                            },
                            items: 2,
                            partialVisibilityGutter: 20,
                          },
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        // swipeable
                      >
                        {featuredDJs?.data?.Platinum.map((profile) => (
                          <div key={profile.plan.user_id} className="shadow-sm h-100 mx-2">
                            <Card className="h-100">
                              <CardImg
                                top
                                src={profile?.plan?.user_profile?.profile_image_path || placeHolderImg}
                                alt={profile?.plan?.user_profile?.company_name}
                                className="rounded-top"
                                style={{ height: "250px", objectFit: "cover", cursor: "pointer" }}
                              />
                              <CardBody className="p-4 d-flex flex-column justify-content-between">
                                <div style={{ cursor: "pointer" }}>
                                  <CardTitle tag="h5">{profile?.plan?.user_profile?.company_name}</CardTitle>
                                  <CardText className="text-muted">
                                    {profile?.plan?.user_profile?.city || "---"}
                                  </CardText>
                                  <CardText className="mb-3">
                                    Starting from: ${" "}
                                    {profile?.plan?.events?.[0]?.event_financial?.min_package_amount || 0}
                                  </CardText>
                                  <CardText>
                                    {profile?.plan?.user_profile?.uniqueness &&
                                    profile?.plan?.user_profile?.uniqueness.length > 200 ? (
                                      <>
                                        {profile?.plan?.user_profile?.uniqueness.substring(0, 200)}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="text-primary fw-bold"
                                          onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                                        >
                                          ...
                                        </span>
                                      </>
                                    ) : (
                                      profile?.plan?.user_profile?.uniqueness || "----"
                                    )}
                                  </CardText>
                                </div>
                                <div className="mt-3">
                                  <Button
                                    color="primary"
                                    className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                                    onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                                  >
                                    View Details
                                  </Button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </Card>
                )}
              </div>
            </Row>
          )}
        </div>
      </Card>

      <div
        style={{
          // minHeight: "90.5vh",
          position: "relative",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        >
          <source src={vidDJ} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <Card className="border mt-0">
        <div className="p-4 px-4" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="text-center mb-4">
            <h2 className="fs-2 lead-text mb-3">Weekly Top Performers</h2>
            <p className="lead text-muted">
              Dive into this week's comprehensive analysis of the top-performing DJ companies, where we break down key
              performance metrics and emerging trends. This detailed report offers valuable insights into the success
              and strategies of leading DJs, showcasing how they are shaping the entertainment industry. From event
              engagement levels and client feedback to innovative techniques and cutting-edge technology, you'll gain an
              in-depth understanding of what sets these top players apart. Whether you're looking to track industry
              trends or identify best practices, this report provides a clear overview of the DJ companies that are
              excelling and setting new standards for performance excellence.
            </p>
          </div>
          {featuredDJsDjsLoading ? (
            <Row>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
              <Col lg={3}>
                <ShimmerThumbnail height={250} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={180} />
                <ShimmerThumbnail height={25} width={220} />
                <ShimmerThumbnail height={25} width={300} />
                <ShimmerButton size="md" />
              </Col>{" "}
            </Row>
          ) : featuredDJsDjsError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={placeHolderImg} alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
              {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
            </div>
          ) : (
            <Card style={{ backgroundColor: "#F8F9FA" }}>
              {featuredDJs?.data?.Platinum.length < 0 ? (
                <div className="d-flex justify-center align-center h-100px fs-5">No Data Found</div>
              ) : (
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className="p-0  m-0"
                  containerClass=""
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  pauseOnHover
                  renderArrowsWhenDisabled={false}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={{
                    desktop: {
                      breakpoint: {
                        max: 3000,
                        min: 1440,
                      },
                      items: 4,
                      partialVisibilityGutter: 40,
                    },
                    mobile: {
                      breakpoint: {
                        max: 600,
                        min: 0,
                      },
                      items: 1,
                      partialVisibilityGutter: 30,
                    },
                    tablet: {
                      breakpoint: {
                        max: 1024,
                        min: 600,
                      },
                      items: 2,
                      partialVisibilityGutter: 20,
                    },
                  }}
                  rewind={false}
                  rewindWithAnimation={false}
                  rtl={false}
                  shouldResetAutoplay
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  // swipeable
                >
                  {featuredDJs?.data?.Platinum?.slice(0, 5).map((profile) => (
                    <div key={profile.plan.user_id} className="shadow-sm h-100 mx-2">
                      <Card className="h-100">
                        <CardImg
                          top
                          src={profile?.plan?.user_profile?.profile_image_path || placeHolderImg}
                          alt={profile?.plan?.user_profile?.company_name}
                          className="rounded-top"
                          style={{ height: "250px", objectFit: "cover", cursor: "pointer" }}
                        />
                        <CardBody className="p-4 d-flex flex-column justify-content-between">
                          <div style={{ cursor: "pointer" }}>
                            <CardTitle tag="h5">{profile?.plan?.user_profile?.company_name}</CardTitle>
                            <CardText className="text-muted">{profile?.plan?.user_profile?.city || "---"}</CardText>
                            <CardText className="mb-3">
                              Starting from: $ {profile?.plan?.events?.[0]?.event_financial?.min_package_amount || 0}
                            </CardText>
                            <CardText>
                              {profile?.plan?.user_profile?.uniqueness &&
                              profile?.plan?.user_profile?.uniqueness.length > 200 ? (
                                <>
                                  {profile?.plan?.user_profile?.uniqueness.substring(0, 200)}
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="text-primary fw-bold"
                                    onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                                  >
                                    ...
                                  </span>
                                </>
                              ) : (
                                profile?.plan?.user_profile?.uniqueness || "----"
                              )}
                            </CardText>
                          </div>
                          <div className="mt-3">
                            <Button
                              color="primary"
                              className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                              onClick={() => navigate(`/dj-profiles/specificDj/${profile?.plan?.user_id}`)}
                            >
                              View Details
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </Carousel>
              )}
            </Card>
          )}
        </div>
      </Card>
    </>
  );
};

export default TopDjs;
