import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import vid1 from "../../../images/vid1.mp4";
import vid2 from "../../../images/vid2.mp4";
import vid3 from "../../../images/vid3.mp4";
import vid5 from "../../../images/vid5.mp4";
import vid6 from "../../../images/vid6.mp4";
import { BlockTitle } from "../../../components/Component";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";

const VideoSlider = () => {
  const navigate = useNavigate();
  return (
    <>
      <Swiper
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper h-100"
      >
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            autoPlay
            loop
            muted
            width="100%"
            className="opacity-75"
            style={{ filter: "brightness(0.7) opacity(0.5)" }}
          >
            <source src={vid5} type="video/mp4" />
            <source src={vid5} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-5 px-5 h-250px text-white">
            <div className="my-3">
              <span className="fs-1">DJs from Around the World</span>
            </div>
            <div className="my-3 ">
              <span className="fs-5 w-100">
                DJs from around the world bring their unique cultural influences to their performances, blending global
                music styles with local favorites to create diverse and dynamic sets.
              </span>
            </div>
            <div className="my-3">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                Book Today
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            autoPlay
            loop
            muted
            width="100%"
            className="opacity-75"
            style={{ filter: "brightness(0.5) opacity(0.5)" }}
          >
            <source src={vid2} type="video/mp4" />
            <source src={vid2} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-5 px-5 h-250px text-white">
            <div className="my-3">
              <span className="fs-1">Weddings</span>
            </div>
            <div className="my-3">
              <span className="fs-5 w-100">
                Your wedding day is one of the most important moments in your life, and our DJs are dedicated to making
                it unforgettable. With a deep understanding of the perfect mix of romance, celebration, and fun, we
                create a personalized soundtrack that reflects your love story. From the emotional first dance to the
                lively reception, our professional DJs will keep your guests entertained and the dance floor packed all
                night long.
              </span>
            </div>
            <div className="my-3">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                Book Today
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            autoPlay
            loop
            muted
            width="100%"
            className="opacity-75"
            style={{ filter: "brightness(0.5) opacity(0.5)" }}
          >
            <source src={vid6} type="video/mp4" />
            <source src={vid6} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-5 px-5 h-250px text-white">
            <div className="my-3">
                <span className="fs-1">Corporate Events</span>
            </div>
            <div className="my-3">
              <span className="fs-5 w-100">
                Elevate your corporate event with a DJ service that understands the balance between professionalism and
                entertainment. Our experienced DJs specialize in creating the perfect ambiance, whether you’re hosting a
                formal gala, an upbeat team-building event, or a lively holiday party. We tailor our playlists to suit
                your company’s culture and the event’s objectives, ensuring that every moment is enhanced by the right
                music.
              </span>
            </div>
            <div className="my-3">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                Book Today
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            autoPlay
            loop
            muted
            width="100%"
            className="opacity-75"
            style={{ filter: "brightness(0.5) opacity(0.5)" }}
          >
            <source src={vid3} type="video/mp4" />
            <source src={vid3} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-5 px-5 h-250px text-white">
            <div className="my-3">
                <span className="fs-1">Virtual DJ</span>
            </div>
            <div className="my-3">
              <span className="fs-5 w-100">
                Bring the energy of a live event to your virtual gathering with our top-tier Virtual DJ services.
                Whether it’s a virtual conference, an online party, or a remote team-building event, our DJs know how to
                keep the vibe alive and your audience engaged, no matter where they are. We offer seamless integration
                with popular video conferencing platforms, customized playlists, and interactive elements that make your
                virtual event feel as dynamic and exciting as an in-person celebration.
              </span>
            </div>
            <div className="my-3">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                Book Today
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="align-center justify-content-center d-flex">
          <video
            autoPlay
            loop
            muted
            width="100%"
            className="opacity-75"
            style={{ filter: "brightness(0.5) opacity(0.5)" }}
          >
            <source src={vid1} type="video/mp4" />
            <source src={vid1} type="video/ogg" />
            Your browser does not support the video.
          </video>
          <div className="position-absolute flex-column align-start justify-center mx-5 px-5 h-250px text-white">
            <div className="my-3">
                <span className="fs-1">Hip-Hop</span>
            </div>
            <div className="my-3">
              <span className="fs-5 w-100">
                Experience the heartbeat of urban culture with our dedicated Hip-Hop DJ services. Our DJs are experts in
                spinning the hottest tracks and timeless classics, delivering a high-energy performance that resonates
                with true Hip-Hop lovers. From old-school anthems to the latest hits, we blend beats that keep the crowd
                moving and the vibe electric. Whether it’s a club night, a private party, or a special event, our
                Hip-Hop DJs
              </span>
            </div>
            <div className="my-3">
              <Button
                className="w-150px justify-center rounded-3 py-2"
                color="primary"
                onClick={() => navigate("/dj-profiles/1")}
              >
                Book Today
              </Button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default VideoSlider;
