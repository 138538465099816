import React, { useState } from "react";
import { Block, BlockTitle, PreviewCard } from "../../components/Component";
import { Collapse } from "reactstrap";
import Content from "../../layout/content/Content";

const FAQ_DJ = ({ className, variation }) => {
  const [isOpen, setIsOpen] = useState("0");
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  return (
    <div id="check" className=" border">
      <Block size="lg">
        <Content className="p-0">
          <BlockTitle page className="mb-2 text-primary" tag="h3">
            <span>FAQ's</span>
          </BlockTitle>
          <PreviewCard className="border-0">
            <div
              className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}
            >
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("2")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "2" ? "title text-primary" : "title"}>What is I Party DJ MIX?</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      I Party DJ MIX is a platform designed to help DJs connect with clients, manage bookings, and grow
                      their business. It offers tools for scheduling, payments, and communication to ensure seamless
                      event management.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "7" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("7")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "7" ? "title text-primary" : "title"}>How Do I Get Booked as a DJ?</h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "7" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Clients can find your DJ profile on I Party DJ MIX. Once they decide to book, you'll receive a
                      notification to confirm the details and finalize the booking.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("1")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "1" ? "title text-primary" : "title"}>
                    How Secure Is the Online Payment Process?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      I Party DJ MIX ensures a safe and secure payment process, protecting both you and your clients
                      through encrypted transactions and trusted payment gateways.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("3")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "3" ? "title text-primary" : "title"}>
                    Can Clients See Reviews and Ratings of My DJ Services?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Yes, clients can leave reviews and rate your services after an event. These ratings help build
                      your reputation on the platform, attracting more clients.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "5" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("5")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "5" ? "title text-primary" : "title"}>
                    How Do I Ensure I Play the Music the Client Wants?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "5" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      I Party DJ MIX allows you to communicate directly with clients before the event. You can discuss
                      music preferences, event details, and create a playlist to match their expectations.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "6" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("6")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "6" ? "title text-primary" : "title"}>
                    What Happens If There Are Technical Issues During an Event?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "6" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      As a DJ, it’s important to be prepared for technical difficulties. We recommend having backup
                      equipment and a plan to troubleshoot issues quickly to keep the event running smoothly.
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "8" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("8")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "8" ? "title text-primary" : "title"}>
                    Can I Communicate with Clients Before the Event?
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "8" ? true : false}>
                  <div className="accordion-inner">
                    <p>
                      Yes, I Party DJ MIX provides a messaging system where you can discuss details, music preferences,
                      and any special requests with clients before the event to ensure everything goes as planned.
                    </p>
                  </div>
                </Collapse>
              </div>
            </div>
          </PreviewCard>
        </Content>
      </Block>
    </div>
  );
};

export default FAQ_DJ;
